@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.container-teams{
  margin-top: 1rem;
  width: 98vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gallery_heading_container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  margin-left: 2vw;
}
.TitleTechClub{
  color: rgb(221, 221, 221);
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teams-heading-underline{
  margin-top: 0.3rem;
  display: flex;
  width: 53.5rem;
  height: 0.3rem;
  background: rgb(173 131 241);
}
.SlideshowTeam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  width: 86vw ;
}
@media only screen and (max-width:1024px) {
  .container-teams{
    margin-top: 5rem;
    width: 100vw;
  }
  .arrow{
    display: none;
  }
  .TitleTeam1{
    font-size: 3rem;
  }
  .TitleTechClub{
    font-size: 2rem;
  }
  .teams-heading-underline{
    width: 35.5rem;
    height: 0.3rem;
  }
}
@media only screen and (max-width:768px) {
  .container-teams{
    margin: 0;
    gap: 2rem;
    height: 72vh;
  }
  .TitleTeam1{
    font-size: 2.5rem;
  }
  .TitleTechClub{
    font-size: 1.2rem;
  }
  .teams-heading-underline{
    width: 21.2rem;
  }
}
@media only screen and (max-width:425px) {
  .container-teams{
    margin-top: 3rem;
    gap: 2rem;
    height: 100%;
  }
  .TitleTeam1{
    font-size: 1.5rem;
  }
  .TitleTechClub{
    font-size: 1rem;
  }
  .teams-heading-underline{
    width: 17.7rem;
    height: 0.2rem;
  }
}
.slide img {
  width: 100%;
  height: 50%;
}
.slide {
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.6;
  background-color: transparent;  
}
.activeSlide {
  transform: scale(1.25);
  opacity: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  text-align: center;
}
.arrow {
  position: absolute;
  margin-top: -2%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 1vh 1vh;
  color: white;
  font-size: 1.5rem;
  border: 2px solid #D23030;
  border-radius: 50%;

}
.arrow svg {
  transition: color 300ms;
}
.arrow svg:hover {
  color:#e5e5e5;
}
.next {
  right: 0%;
  top: 50%;
}
.prev {
  left: 0%;
  top: 50%;
}
.innercontainerTeam{
  width: 79%;  
  background-color: #11051D  ;
  margin-left:10.5%;
  margin-top: -45%;
  border-bottom: 2px solid #644D92;
  margin-bottom: 5% ;
}
.App-logo {
  height: 40vmin;
  pointer-events: none; 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
.App-link {
  color: #61dafb;
}
/* .TitleTeamsinner{
  display: flex;
  padding-top: 2%;
  flex-direction:column;
  width: 60%;
  margin-left: 20%;
  height: 100vh;
  margin-top: 2%;
  font-size: 2.7rem ;
  color: white;
} */

@media screen and (max-width:900px) and (min-width:701px) {
  .slide{
    margin-left: 0%;
    transform: scale(0.6);
  }
  .activeSlide{
    transform: scale(0.7);
  }
  .slick-track{
    height: 300px;
    margin-top: 0%;
   
  }
}
@media screen and (max-width:500px) {
  .SlideshowTeam{
    margin:0%;
    width: 100%;
  }
  .slide{
    padding-top: 15%;
    transform: scale(.7);
  }
  .slick-track{
    height: 320px;
    padding-top: 5%;
    margin-top: -25%;
  }
}
@media screen and (max-width:700px) and (min-width:501px) {
  .slide{
    margin-left: -25%;
    transform: scale(0.5);
  }
  .activeSlide{
    transform: scale(0.6);
  }
  .slick-track{
    height: 300px;
    margin-top: 0%;
  }
}