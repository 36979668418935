.navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4rem;
    font-size: 1.2rem;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: transparent;
}
.header-wrapper {
    width: 98.7vw;
}
.header {
    position: sticky;
    background: transparent;
    background-color: transparent;
    z-index: 1000;
    width: 100%;
    transition: all 0.5s ease-in-out;
}
.header.active {
    background: rgba(196, 195, 195, 0.6);
    color: black;
    backdrop-filter: blur(12px);
    transition: 0.5s ease-in-out;
}
.nav-container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 5rem;
    max-width: 100vw;
}
.yp-logo-header{
    display: flex;
    width: 10%;
}
.nav-links{
    cursor: pointer;
}
.nav-logo{
    width: 3rem;
    color: #edf0f1;
    cursor: pointer;
}
.nav-menu{
    display: flex;
    justify-content: space-around;
    list-style: none;
    text-align: center;
}
.nav-links{
    text-decoration: none;
    padding: 0.7vh 1vw;
    background-color: transparent;
    transition:  0.5s;
    border-bottom: 1px solid transparent;
}
.nav-links:hover{
    border-bottom: 3px solid #D23030;
    border-radius: 1px;
    transition: 0.2s ease;
}
.nav-item{
    line-height: 40px;
    margin-right: 1vw;
}
.nav-item .active{
    border-bottom: 3px solid #D23030;
    border-radius: 1px;
}
.nav-icon{
    display: none;
}
.Registration-Link{
    text-decoration: none;
    color: #edf0f1;
}
@media screen and (max-width: 900px) {
    .nav-container{
        display: flex;
        justify-content: flex-start;
        height: 11vh;
    }
    .nav-logo{
        position: absolute;
        top: 4vh;
        left: 4vw;
        width: 3rem;
        display: flex;
        justify-content: center;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #D23030;
        position: absolute;
        top: 10vh;
        left: -110%;
        opacity: 0;
        z-index: 10;
    }
    .nav-menu.active{
        background: rgba(196, 195, 195);;
        left: 0;
        height: 90vh;
        opacity: 1;
        padding: 0 0;
        margin: 0 0;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item.active{
        border: none;
    }
    .nav-item.contact{
        padding: 1rem;
    }
    .nav-links{
        padding: 1rem 0rem;
        width: 100%;
        display: table;
        color: black;
    }
    .yp-logo-header{
        display: flex;
        justify-content: flex-start;
    }
    .nav-icon{
        display: flex;
        color: black;
        position: absolute;
        top: 4vh;
        right: 4vw;
        cursor: pointer;
        transform: translate(-100%,60%);
        font-size: 1.5rem;
    }
    .contact-button{
        margin-bottom: 1vh;
    }
}
@media screen and (max-width: 600px) {
.nav-logo{
    position: absolute;
    top: 3vh;
    left: 4vw;
    width: 3rem;
    display: flex;
    justify-content: center;
}
.yp-logo-header{
    display: flex;
    justify-content: flex-start;
}
.nav-icon{
    color: black;
}
.nav-container{
    height: 10vh;
}
}