.Exec-Card{
    background-color: #E5E5E5;
    width: 100%;
    margin: 5vh 0;
    text-align: center;
    padding: 1vh 1vw;
    box-shadow: 1px -1px 4px;
    transition: 0.5s;
    color: black;
}
.Council-card-img{
    width: 250px;
}