body{
    background-color: #252525;
    color: #C7C3BA;
    font-family: 'Lato', sans-serif;
}
/* should not be needed when compiled aka insertng default elements */

.about_jyc{
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
    justify-content: flex-end;
    padding-left: 2vw;
}
.about_heading_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: underline;
    margin-left: 2vw;
}
.textbox{
    font-size: 1.4rem;
    text-align: justify;
}
.about_part{
    display: flex;
    text-align: justify;
    flex-direction: row;
    padding: 2.5vh 3vw;
}
.decoration1{
    width: 4.5vw;
    height: auto;
    margin: 1vw 0vw -7vw -2vw;
    
}
.decoration2{
    width: 5vw;
    height: auto;
    margin: 0vw 0vw -10vw -2vw;
}
.img1{
    width: 40vw;
}
.img2{
    width: 31vw;
    margin: -14vw -5vw 0vw 0vw;
}
.paragraph_jyc{
    display: flex;
    flex-direction: column;
    width: 60vw;
    padding-left: 2vw;
    text-align: justify;
    justify-content: space-around;
}
/* .heading{
    
    text-shadow: 4.8px 5.1px 2px rgba(0, 0, 0, 0.25);
    font-family: 'Oswald', sans-serif;
    font-size: 2.5vw;
    z-index: 1;
} */
.heading_flex{
    display: flex;
    align-items: baseline;
}
@media (max-width: 800px){
    .about_part{
        flex-direction: column;
        padding: 1.5vw 1vw;
    }
    .about_jyc{
        font-size: 5rem;
    }
    .paragraph_jyc{
        width: 90vw;
    }
    .about_part_rev{
        flex-direction: column-reverse;
    }
    .img2{
        display: none;
    }
    .textbox{
        /* font-size: 3.5vw; */
    }
    .heading{
        /* font-size: 5vw; */
    }
    .about_jyc{
        /* font-size: 7vw; */
        padding: 1rem;
    }
    .img1{
        display: none;
    }
    .heading_flex{
        margin-left: 2vw;
    }
    .decoration1 {
        width: 5.5vw;
    
    }
    .decoration2 {
        width: 7vw;
        margin: 0vw 0vw -5vw -1vw;
    }
}